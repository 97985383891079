import { ONE_TAP_SIGNIN } from '@graphql/auth/login.mutation'
import { useEffect } from 'react'
import useOnMouseAndScroll from '../useOnMouseAndScroll'
import { GOOGLE_CLIENT_ID } from '@shared/constants'
import { useMutation } from '@apollo/client'
import { setToken } from '@shared/libs/token'
import { useRouter } from 'next/router'
import useWindowSize from '../windowSize'

const useGoogleIdentify = (token) => {
  const url = 'https://accounts.google.com/gsi/client'
  const router = useRouter()
  const { isLoaded } = useOnMouseAndScroll()
  const [width] = useWindowSize()

  const [oneTapSignIn] = useMutation(ONE_TAP_SIGNIN, {
    onCompleted: (data) => {
      if (data && data.socialSignUp !== null) {
        setToken(data.socialSignUp.oData.sToken)
        router.push(router.asPath)
      }
    }
  })

  const initializeGoogleAccounts = () => {
    const { google } = window
    if (google) {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        cancel_on_tap_outside: false,
        prompt_parent_id: 'g_one_tap',
        callback: async (response) => {
          // perform action after initialization
          await oneTapSignIn({
            variables: {
              input: {
                sSocialToken: response.credential,
                sSocialType: 'G'
              }
            }
          })
        }
      })

      google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          console.log('getNotDisplayedReason: ', notification.getNotDisplayedReason())
        } else if (notification.isSkippedMoment()) {
          console.log('isSkippedMoment: ', notification.getSkippedReason())
        } else if (notification.isDismissedMoment()) {
          console.log('isDismissedMoment: ', notification.getDismissedReason())
        }
      })
    }
  }

  useEffect(() => {
    // declare a variable to store the timeout id
    if (!token && isLoaded && router?.pathname !== '/sign-up' && router?.pathname !== '/sign-in' && width && width > 767) {
      if (window?.google) {
        initializeGoogleAccounts()
      } else {
        const script = document.createElement('script')
        script.src = url
        script.async = true
        // script.setAttribute('strategy', 'lazyOnload')
        document.head.appendChild(script)
        script.onload = () => initializeGoogleAccounts()
        return () => {
          script.onload = null
          script.remove()
        }
      }
    }
  }, [token, isLoaded, width])
}

export default useGoogleIdentify
