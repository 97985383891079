import { useEffect, useMemo, useState } from 'react'
import { ViewPortEndpoints } from '@shared/constants'

function useWindowSize() {
  const [size, setSize] = useState([])
  const widthEndPoints = useMemo(() => {
    const ports = new Set()
    ViewPortEndpoints.forEach((port) => {
      ports.add(port - 1)
      ports.add(port)
      ports.add(port + 1)
    })
    return ports
  }, [])
  useEffect(() => {
    setSize([window.innerWidth, window.innerHeight])
    function updateSize() {
      const width = window.innerWidth
      const height = window.innerHeight
      if (widthEndPoints.has(width)) {
        setSize([width, height])
      }
    }
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
export default useWindowSize
