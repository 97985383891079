import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation UserLogin($input: userLogin) {
    userLogin(input: $input) {
      sMessage
      oData {
        sToken
      }
    }
  }
`

export const ONE_TAP_SIGNIN = gql`
  mutation SocialSignUp($input: socialSignupInput!) {
    socialSignUp(input: $input) {
      sMessage
      oData {
        sToken
      }
    }
  }
`
